:root {
  --pink: #fe68b5;
  --blue: #00b8e5;
  --grey: #bfbfbf;
  --darkgrey: #565656;
  --black: #1b1212;
  --white: #f9f6ee;
  --teal: teal;
}
/*# sourceMappingURL=index.09839f58.css.map */
